.container {
  margin: 70px 0;
}

.card {
  margin: 0 2% !important;
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75) !important;
}

.infected {
  border-bottom: 10px solid #5bc1ff;
}

.recovered {
  border-bottom: 10px solid #4fe6c1;
}

.deaths {
  border-bottom: 10px solid #ff775b;
}

@media (max-width: 770px) {
  .card {
    margin: 2% 0 !important;
  }
}
