body {
  background-color: rgb(250, 250, 250);
}

.text {
  margin-top: 35px !important;
  font-family: sans-serif;
  color: #808080;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  length: 4rm;
  background-color: lightgray;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  width: 1000px;
  margin: 70px 0;
}

@media (max-width: 770px) {
  .container {
    margin: 0 5%;
  }

  .image {
    width: 100%;
  }
}
