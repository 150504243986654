.container {
  display: flex;
  justify-content: center;
  width: 90%;
}

@media (max-width: 770px) {
  .container {
    width: 100%;
  }
}
