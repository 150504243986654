.formControl {
  width: 30%;
  margin: 20px 0 20px 0 !important;
}

@media (max-width: 770px) {
  .formControl {
    width: 100%;
    margin-bottom: 0px !important;
  }
}
